.login-banner {
    background-image: none;
    background-position-y: 0;

    @include media-breakpoint-up(md) {
        padding-right: 5%;
    }

    .content-asset {
        width: 100%;
    }
}

.invalid-referrer-id {
    width: 82%;
    margin: auto;
    padding-top: rem(88);
    padding-bottom: rem(48);

    @include media-breakpoint-up(md) {
        width: 48%;
        padding-top: rem(104);
        padding-bottom: rem(64);
    }
}

.maxed-out-referrer {
    width: 83%;
    margin: auto;
    padding-top: rem(88);
    padding-bottom: rem(48);

    @include media-breakpoint-up(md) {
        width: 45%;
        padding-top: rem(104);
        padding-bottom: rem(64);
    }
}

.referral-error-heading {
    font-family: $crimson;
    font-size: rem(24);
    line-height: rem(28);
    font-weight: 400;
    color: $loyalty-heading-color;
    margin-bottom: rem(16);
    text-align: center;

    @include media-breakpoint-up(md) {
        font-size: rem(32);
        line-height: rem(40);
    }
}

.referral-error-info {
    font-family: $gotham-regular;
    font-size: rem(14);
    line-height: rem(20);
    font-weight: 400;
    color: $loyalty-referral-color;
    text-align: center;
    margin-bottom: 0;
}

.login-page {
    max-width: rem(1180);

    @include media-breakpoint-up(md) {
        margin-top: rem(60);
    }

    .login-form-nav {
        //added important to overwrite OOTB dynamic styles
        position: initial !important;
        margin-bottom: 0;
    }

    .card {
        border: none;
        margin-bottom: 0;
    }

    .card-body {
        padding: 0;
    }

    .card-header {
        border-bottom: rem(1) solid $checkout-login-card-heading-border-color;
        padding: 0 0 rem(16);
    }

    .card-header-custom,
    .track-order-header {
        font-family: $gotham-bold;
        font-size: rem(16);
        line-height: rem(24);
        letter-spacing: rem(1);
        color: $form-control-text-color;
        text-transform: uppercase;
    }

    .returning-customers {
        @include media-breakpoint-up(md) {
            padding-left: 5%;
        }

        @include media-breakpoint-down(sm) {
            margin-top: rem(32);
        }

        .info-text {
            margin-top: rem(24);
            line-height: rem(20);
        }

        .remember-me {
            margin: rem(24) 0 0;
        }

        .button-secondary {
            margin-top: rem(40);

            @include media-breakpoint-up(md) {
                margin-top: rem(0);
            }
        }

        .form-group.custom:first-of-type {
            margin-top: rem(24);
        }
    }

    .forgot-password,
    .privacy-policy {
        margin-top: rem(24);
        text-align: center;
    }

    .new-customers {
        @include media-breakpoint-up(md) {
            margin-top: rem(104);
            padding-right: 5%;
        }

        @include media-breakpoint-down(sm) {
            margin-top: rem(50);
        }

        .content-asset {
            font-family: $gotham-regular;
            font-size: rem(14);
            line-height: rem(20);

            p {
                margin: rem(24) 0 0;
            }

            ul {
                font-size: rem(14);
                line-height: rem(20);
                margin-top: rem(24);
            }
        }

        .button-secondary {
            margin-top: rem(40);

            @include media-breakpoint-up(md) {
                margin-top: rem(0);
            }
        }
    }

    .checkorder-status {
        @include media-breakpoint-up(md) {
            margin-top: rem(104);
            padding-left: 5%;
        }

        @include media-breakpoint-down(sm) {
            margin-top: rem(64);
        }

        .button-secondary {
            margin-top: rem(40);

            @include media-breakpoint-up(md) {
                margin-top: rem(0);
            }
        }

        .form-group.custom:first-of-type {
            margin-top: rem(24);
        }
    }

    .track-order-header-text {
        margin-top: rem(24);
    }

    .btn.button-secondary {
        height: rem(56);
        line-height: rem(56);
        margin-top: rem(32);
    }
}

.early-access-login-form {
    .user-logged-in {
        .keep-user-logged-in {
            width: rem(16);
            height: rem(16);
            border: rem(0.5) solid $info-icon-border;
            border-radius: 50%;
            text-align: center;
            line-height: rem(16);
            font-size: rem(10);
            padding: 0;
            display: inline-block;
            cursor: pointer;
            margin-top: rem(4);
        }

        .keep-user-logged-in:hover,
        .keep-user-logged-in:focus {
            background-color: $base-text-color;
            color: $page-title-background-color;
        }
    }
}

.remember-me {
    .logged-in-user-notification {
        display: none;
    }
}

.keep-user-signed-in {
    margin-top: rem(4);
    margin-left: rem(3);

    &:hover {
        background-color: $icon-background-color;
    }
}

.login-page-banner {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;
    background-color: $login-banner-bg;
    background-repeat: no-repeat;
    background-size: cover;

    &-title {
        color: $login-banner-fg;
        font-family: $crimson;
        font-size: rem(32);

        @include media-breakpoint-down(sm) {
            font-size: rem(24);
            padding: rem(40) rem(32);
        }
    }

    &-title-br {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}
